const AboutLinks = [
  {
    title: 'Company',
    submenu: [
      { name: 'About us', link: 'about-us' },
      { name: 'Media', link: 'press' },
      {
        name: 'Global Animal Welfare Fund',
        link: 'global-animal-welfare-fund',
      },
    ],
  },
  {
    title: 'Resources',
    submenu: [
      {
        name: 'Global Roaming blog',
        link: 'http://blog.globalworkandtravel.com/',
        external: true,
      },
      { name: 'Global Experience Guide', link: 'global-experience-guide' },
      { name: 'FAQs', link: 'frequently-asked-questions' },
    ],
  },
  {
    title: 'Work with us',
    submenu: [
      { name: 'Partners', link: 'partners' },
      { name: 'Careers', link: 'careers' },
      { name: 'Affiliates', link: 'affiliate' },
      {
        name: 'Ambassadors',
        link: 'https://globalworkandtravel.trysaral.com/',
        external: true,
      },
    ],
  },
];

export default AboutLinks;
